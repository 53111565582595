import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <p>
            We create 1ntiutive, Beautiful & Neostic applications.
            <br />
            Our products speak for us. We aren't kidding!{' '}
          </p>
          <p>
            Sai Krishna is the Founder & CEO of 1BN.
            <br />
            Founder's website:&nbsp;&nbsp;
            <b>
              <a href="https://saikrishna.me" target="_blank">
                www.saikrishna.me
              </a>
            </b>
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Products</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <h3>Superblog</h3>
          <p>
          Superblog is the best alternative for WordPress and Medium blogs.
          Auto-optimized for SEO and speed with zero setup and maintenance.
           <br/> Used by some of the biggest brands in the world like Swiggy, Unacademy, Aisle, and Drivelah.
            <br />
            website:&nbsp;&nbsp;
            <b>
              <a href="https://superblog.ai" target="_blank">
                www.superblog.ai
              </a>
            </b>
          </p>

          <h3>mHotspot</h3>
          <p>
            mHotspot is a completely free software which converts your laptop
            into a virtual wifi router and creates a secure wifi hotspot. It
            received numerous{' '}
            <a href="https://mhotspot.com/awards" target="_blank">
              awards
            </a>{' '}
            and has been downloaded over 7,000,000 times since then!
            <br />
            website:&nbsp;&nbsp;
            <b>
              <a href="https://mhotspot.com" target="_blank">
                www.mhotspot.com
              </a>
            </b>
          </p>

          <h3>Rey</h3>
          <p>
            Many of us use Youtube to listen to music. But Youtube wasn’t
            designed with music in mind. So, ‘Rey’ was built with simple and
            intuitive UI which takes youtube as data source! It was made
            open-source later.
            <br />
            website:&nbsp;&nbsp;
            <b>
              <a href="https://reymusic.co" target="_blank">
                www.reymusic.co
              </a>
            </b>
          </p>
          <p>
            You can see all other products built by our CEO here:&nbsp;&nbsp;
            <b>
              <a href="https://saikrishna.me/portfolio" target="_blank">
                https://saikrishna.me/portfolio{' '}
              </a>
            </b>
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>

          <p>contact[at]1bn.in</p>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
